import React from 'react'
import Box from '@src/components/atoms/Box'
import ContactBelt from '@src/components/molecules/ContactBelt'
import HeaderWithImage from '@src/components/molecules/HeaderWithImage'
import { Layout } from '@src/components/layouts'

const jobText = 'フルスタックエンジニア募集中。'

const JobPage = ({  }: any) => (
  <div className="jobPage">
    <HeaderWithImage />
    <h1 className="pageTitle">一緒に働きませんか？！</h1>
    <Box>
      <p
        style={{
          textAlign: 'center',
        }}
        dangerouslySetInnerHTML={{ __html: jobText }}
      />
    </Box>
    <ContactBelt />
  </div>
)

export default JobPage
