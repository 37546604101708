import React from 'react'
import Link from 'gatsby-link'

import './style.sass';

const Belt = ({ children }) => (
  <div className="Belt" style={{
    backgroundColor: '#FAFAFA',
    // paddingTop: 80,
    // paddingBottom: 80,
    textAlign: 'center',
    // marginLeft: '-10vw',
    // marginRight: '-10vw',
    marginTop: 80,
  }}>
    {children}
  </div>
)
const Button = (props) => (
  <div
    style={{ ...props.style }}
    className="btn"
  >
    <Link
      to={props.to}
    >
      {props.text}
    </Link>
  </div>
)
const Flex = ({ children, style }) => (
  <div style={{ display: 'flex', ...style }}>
    {children}
  </div>
)
const ContactBelt = () => (
  <Belt>
    <Flex style={{ justifyContent: "space-around" }}>
      <Button
        to="/contact"
        text="Contact Us"
        style={{
          width: '30%',
          minWidth: 300
        }}
      />
    </Flex>
  </Belt>
)

export default ContactBelt
