import React from 'react'

import './style.sass'

const bgimage = require('../../../images/bg-subpage-header.png')

const HeaderWithImage = (props) => (
  <div
    className="headerWithImage"
    css={{
      backgroundImage: `url(${bgimage})`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center center',

      // width: '100vw',
      // height: '15vw',
      // maxHeight: 217
    }}
  >
    {props.children}
  </div>
)

export default HeaderWithImage
